@font-face {
  font-family: "Wensley";
  src: url(./fonts/Wensley.ttf);
}

.purif {
  text-align: Center;
}

.portable {
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: #282828;
  line-height: 25px;
  text-align: Center;
  margin-bottom: 15px;
}

.portable-1 {
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: #282828;
  line-height: 25px;
  text-align: Center;
  margin-bottom: 15px;
}

.nom-0 img {
  width: 100%;
}

.nomp img {
  width: 100%;
}

.Along {
  font-weight: 400;
  font-size: 40px;
  font-family: "Wensley";
  color: #282828;
}

/* Add these styles to your CSS file */
.clip {
  -webkit-clip-path: polygon(57% 9%,
      100% 0,
      100% 43%,
      100% 100%,
      -46% 91%,
      41% 91%,
      0 100%,
      0 0,
      43% 9%);
  clip-path: polygon(57% 9%,
      100% 0,
      100% 43%,
      100% 100%,
      -46% 91%,
      41% 91%,
      0 100%,
      0 0,
      43% 9%);
}

.swip {
  height: 280px;
  width: 250px;
  min-width: 300px;
  margin-right: 10px;

  background-size: cover;
}

.log {
  width: 12%;
}

.Magg-1111 {
  height: 100vh;
  background-color: #282828;
}

.type {
  color: white;
}

.nopee {
  background-color: transparent;
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-size: 22px;
}

.cut-633 {
  font-weight: 400;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  transform-origin: 0 0;
  transform: rotate(90deg);
  margin-top: 20px;
  margin-left: -80px;
}

.cut-44 {
  position: absolute;
  font-weight: 200;

  left: 0;
  bottom: 7rem;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  transform: rotate(90deg);
}

.world {
  text-align: center;
  vertical-align: middle;
  color: white;
  /* margin-top: -18px; */
  font-family: "Wensley";
  font-weight: 400;
  font-size: 28px;
  letter-spacing: 10px;
}

@media only screen and (max-device-width: 414px) {
  .world {
    text-align: center;
    vertical-align: middle;
    color: white;
    /* margin-top: -18px; */
    font-family: "Wensley";
    font-weight: 400;
    font-size: 81.25%;
    /* font-size: 13px; */
    letter-spacing: 10px;
  }
}

.position {
  position: absolute;
  top: 45%;

  display: block;
  left: 14px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.pick {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* header */

.logo img {
  z-index: 1;
}

.knock {
  height: 50vh;
  z-index: 2;
}

/* 
header end */

/* Omega Component */

.omeg {
  /* height: 110vh; */
  background: url(./images/home-1.png);
  background-size: cover;
  margin-top: -820px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-bottom: 30px;
}

.port-1 {
  height: 110vh;
  background-image: url(./images/portfolio.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -820px;
}

.Avers {
  height: 110vh;
  background-image: url(./images/pihillosophy.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -820px;
}

.Coverage {
  height: 110vh;
  background-image: url(./images/wealth-managment.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -820px;
}

.scan {
  height: 110vh;
  background-image: url(./images/scan.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -820px;
}

.soff {
  height: 110vh;
  background-image: url(./images/swiss.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -820px;
}

.Doc {
  height: 110vh;
  background-image: url(./images/News.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -820px;
}

.Duret {
  height: 110vh;
  background-image: url(./images/Duret.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -820px;
}

/* Component Mission */

.robo-1 {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 80px;
}

.robo {
  padding-top: 40px;
  padding-bottom: 40px;
}

.phillo {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #282828;
}

.phillo-1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #282828;
  line-height: 24px;
  margin-right: 150px;
}

.prof {
  justify-items: center;
}

.mask {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.mask-1 {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.mask-3 {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.Mission {
  font-family: "Wensley";
  font-weight: 400;
  font-size: 70px;
  text-align: center;
  line-height: 110px;
  color: #282828;
}

.custom {
  font-family: "Wensley";
  font-weight: 400;
  font-size: 45px;
  line-height: 65px;
  color: #282828;
}

.custom-dob {
  font-family: "Wensley";
  font-weight: 400;
  font-size: 45px;
  line-height: 65px;
  color: #282828;
}

/* component css end */

/* philosophy-wealth start */

.Magg {
  background-color: #282828;
  height: 110vh;
}

.weal {
  margin-top: 60px;
  height: 600px;
  width: 630px;
}

.cut-1 {
  font-weight: 400;
  font-size: 50px;
  font-family: "Wensley";
  color: #ffffff;
}

.cut-2 {
  font-weight: 400;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
}

.cut-3 {
  font-weight: 400;
  font-size: 60px;
  font-family: "Wensley";
  color: #ffffff;
}

.cut-4 {
  padding-top: 20px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  line-height: 24px;
  padding-right: 70px;
}

.cut-5 {
  padding-top: 20px;
  font-weight: 400;
  font-size: 24px;
  font-family: "Wensley";
  color: #ffffff;
}

.cut-6 {
  font-weight: 400;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  transform-origin: 0 0;
  transform: rotate(90deg);
  margin-top: 20px;

  margin-left: -60px;
}

.cut-64 {
  font-weight: 400;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  transform-origin: 0 0;
  transform: rotate(90deg);
  margin-top: 20px;
  margin-left: -60px;
  margin-bottom: 90px;
}

.cut-69 {
  font-weight: 400;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  color: #282828;
  transform-origin: 0 0;
  transform: rotate(90deg);
  margin-top: 8px;
  margin-left: 360px;
  margin-bottom: 60px;
}

.cut-111 {
  font-weight: 400;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  transform-origin: 0 0;
  transform: rotate(90deg);
  margin-top: 20px;
  margin-left: -70px;
}

/* philosophy-wealth end */

/* philosophy-ahead */

.Magg-1 {
  height: 100vh;
  margin-top: 50px;
}

.weal-11 {
  width: 100%;
}

.cut-11 {
  font-weight: 400;
  font-size: 50px;
  font-family: "Wensley";
  color: #282828;
}

.cut-12 {
  font-weight: 400;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #282828;
}

.cut-13 {
  font-weight: 400;
  font-size: 60px;
  font-family: "Wensley";
  color: #282828;
}

.cut-14 {
  padding-top: 20px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #282828;
  line-height: 24px;
  padding-right: 70px;
}

.cut-15 {
  padding-top: 20px;
  font-weight: 400;
  font-size: 24px;
  font-family: "Wensley";
  color: #282828;
}

.speed-1 {
  margin-top: 210px;
}

.rangs {
  padding-left: 20px;
}

.cut-16 {
  font-weight: 400;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  color: #282828;
  transform-origin: 0 0;
  transform: rotate(90deg);
  margin-top: 50px;
  margin-left: 360px;
}

/* philosophy-ahead compoent end */

/* philosophy-Rigorous  */
.Magg-111 {
  height: 110vh;
  background-color: #282828;
}

.weal-111 {
  width: 100%;
  height: 100vh;
}

.cut-116 {
  font-weight: 400;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  transform-origin: 0 0;
  transform: rotate(90deg);
  margin-top: -120px;

  margin-left: 70px;
}

.cut-31 {
  font-weight: 400;
  font-size: 50px;
  font-family: "Wensley";
  color: #ffffff;
}

.rangss {
  margin-top: 30px;
  margin-left: 20px;
}

/* philosophy-Rigorous end */

/* Remind start */

.band {
  margin-left: 30px;
}

.Magg-11 {
  margin-top: 50px;
}

/* Remind end */

/* Managment component */

/* Unqiue */

.manage-1 {
  width: 100%;
  height: 100%;
}

.manage-3 {
  font-weight: 400;
  font-size: 45px;
  font-family: "Wensley";
  color: #ffffff;
}

/* Unique end */

/* Asset start */

.Asset-13 {
  font-weight: 400;
  font-size: 30px;
  font-family: "Wensley";
  color: #282828;
}

/* Asset end */

/* Managment component end */

/* Portfolio component start */

/* Allocation comp */

.Allocate {
  font-weight: 400;
  font-size: 60px;
  font-family: "Wensley";
  color: #ffffff;
}

.Allocate-4 {
  font-weight: 400;
  font-size: 60px;
  font-family: "Wensley";
  color: #282828;
}

.speed-4 {
  margin-top: 310px;
}

/* Allocation end */
/* Time start */

.tomm {
  height: 100%;
  background-color: #282828;
}

/* Time end */

/* Portfolio component end */

/* Team component start */

/* Board comp strt */

.Invent {
  font-weight: 400;
  font-size: 17px;
  font-family: "Wensley";
  color: #0000004d;
}

.Did {
  font-weight: 400;
  font-size: 20px;
  font-family: "Wensley";
  color: #282828;
  margin-top: 20px;
}

.boss {
  text-align: center;
  margin-top: 50px;
}

.boss p {
  font-weight: 400;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  color: #282828;
  line-height: 25px;
}

.boss img {
  width: 80%;
}

.nav-tabs {
  border: none;
}

.nav-tabs .active {
  border: none;
}

.nav-tabs :hover {
  border: none;
}

.Direct {
  font-weight: 400;
  font-size: 35px;
  font-family: "Wensley";
  color: #000000;
  margin-top: 20px;
  margin-bottom: -70px;
}

.tab-content {
  margin-top: 60px;
}

.Abss {
  background-color: #282828 !important;
}

.moda {
  text-align: center;
}

.Bena {
  font-weight: 400;
  font-size: 40px;
  font-family: "Wensley";
  color: #ffffff;
}

.pong {
  border-top: 1px solid #ffffff33;
  border-bottom: 1px solid #ffffff33;
  margin-top: 10px;
  margin-bottom: 10px;
}

.comm {
  font-weight: 600;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: FFFFFF !important;
  line-height: 25px;
}

.coock {
  padding-top: 20px;
  font-weight: 600;
  font-size: 38px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  line-height: 24px;
}

ul.loof {
  color: #ffffff;
}

li.long {
  font-weight: 400;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  color: FFFFFF !important;
  line-height: 25px;
}

.coock-1 {
  padding-top: 20px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  line-height: 24px;
}

.modal-header {
  border-bottom: none !important;
}

.modal-header .btn-close {
  color: #ffffff;
  margin-left: 1px;
}

/* Board comp end */

/* Team Component end */

/* Contact component */
.cops {
  background-color: #282828;
}

.oop {
  border-bottom: 1px solid #ffffff33;
  padding-top: 100px;
  padding-bottom: 100px;
}

.contact {
  font-weight: 400;
  font-size: 75px;
  font-family: "Wensley";
  color: #ffffff;
}

.section-2 {
  background-color: #282828;
  padding-top: 100px;
  padding-bottom: 100px;
}

.swiss {
  font-weight: 400;
  font-size: 40px;
  font-family: "Wensley";
  color: #ffffff;
}

.Geneva {
  font-weight: 400;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  line-height: 34px;
}

.dot {
  border-left: 2px solid #ffffff;
  padding-left: 10px;
  margin-top: 20px;
}

.phone {
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  line-height: 34px;
  margin-bottom: -10px;
}

.number {
  font-weight: 400;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  line-height: 34px;
}

.soft {
  padding-left: 40px;
}

.soft-1 {
  padding-left: 60px;
}

.limi {
  padding-top: 32px;
}

/* Contact component start */

/* footer :start */

.foot {
  background-color: #282828;
}

.feet li {
  display: inline;
  padding-left: 25px;
}

ul.feet {
  text-align: end;
}

.read {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Wensley";
  color: #ffffff;
  text-transform: capitalize;
}

.reading {
  font-weight: 400;
  font-size: 14px;
  font-family: "Wensley";
  color: #ffffff;
}

.roofi {
  text-align: end;
}

.read-1 {
  text-align: center;
}

.read-3 {
  text-align: end;
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  text-decoration: underline;
}

.floop {
  padding-left: 50px;
  padding-right: 50px;
}

.fame {
  font-weight: 300;
  font-size: 35px;
  font-family: "Wensley";
  color: #ffffff4d;
}

.type {
  border-bottom: 1px solid#FFFFFF;
  background-color: transparent;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  width: 500px;
  height: 39px;
}

.foom {
  padding-top: 70px;
  padding-bottom: 30px;
}

.feel-1 {
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
}

.cond {
  border-top: 1px solid #fdfdfd4d;
  margin-top: 20px;
}

/* footer :End */

/* news tab */
.popp {
  margin-top: 50px;
  margin-bottom: 50px;
}

.noen {
  font-weight: 400;
  font-size: 70px;
  font-family: "Wensley";
  color: #000000;
  margin-bottom: 50px;
}

.nom {
  text-align: center;
}

/* news tab end */

/* circular nav */

/* 
component media query start */

@media only screen and (min-width: 992px) {
  .custom-2 {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .custom-2 {
    display: none;
  }
}

@media only screen and (min-width: 1700px) {
  .custom-2 {
    display: none;
  }

  .phillo-1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #282828;
    line-height: 24px;
    margin-right: 300px;
  }

  /* .omeg {
    margin-top: -1035px;
  } */

  .port-1 {
    margin-top: -1035px;
  }

  .Avers {
    margin-top: -1035px;
  }

  .Coverage {
    margin-top: -1035px;
  }

  .scan {
    margin-top: -1035px;
  }

  .soff {
    margin-top: -1035px;
  }

  .Doc {
    margin-top: -1035px;
  }

  .Duret {
    margin-top: -1035px;
  }

  .navigation-circle {
    display: block;
    position: relative;
    height: 480px;
    width: 480px;
    margin: auto !important;
  }
}

/* media query for mobile */

@media only screen and (max-width: 600px) {
  .Mission {
    font-family: "Wensley";
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    line-height: 50px;
    color: #282828;
    margin-bottom: 30px;
  }

  .custom {
    font-family: "Wensley";
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #282828;
    margin-top: 20px;
  }

  .custom-2 {
    font-family: "Wensley";
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #282828;
    margin-top: 20px;
  }

  .mask {
    width: 100%;
    height: 100%;
    text-align: left;
  }

  .mask-1 {
    width: 100%;
    height: 100%;
  }

  .mask-3 {
    width: 100%;
    height: 100%;
  }

  .robo-1 {
    padding-top: 1px;
    padding-bottom: 20px;
    padding-left: 10px;
  }

  .robo {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .custom-dob {
    display: none;
  }

  .phillo-1 {
    margin-right: 1px;
  }

  .phillo {
    font-size: 17px;
  }

  /* for wealth component */
  .speed {
    background-color: #282828;
  }

  .weal {
    height: 100%;
    width: 100%;
  }

  .cut-3 {
    font-size: 40px;
  }

  .cut-4 {
    padding-right: 10px;
  }

  .cut-6 {
    display: none;
  }

  .cut-64 {
    display: none;
  }

  /* for wealth end */
  /* ahead component  */
  .cut-13 {
    font-weight: 400;
    font-size: 25px;
    font-family: "Wensley";
    color: #282828;
  }

  .cut-14 {
    padding-right: 10px;
  }

  .speed-1 {
    margin-top: 10px;
  }

  .cut-16 {
    display: none;
  }

  .cut-633 {
    display: none;
  }

  .cut-116 {
    display: none;
  }

  .cut-111 {
    display: none;
  }

  .cut-44 {
    display: none;
  }

  .rangs {
    padding-top: 20px;
  }

  .Magg-1 {
    margin-top: 1px;
    height: 100%;
  }

  /* ahead component end */
  /* Rigorous start */

  .weal-111 {
    width: 100%;
    height: 100%;
  }

  .Magg-111 {
    height: 100%;
    background-color: #282828;
  }

  .speedo {
    margin-top: 30px !important;
    padding-bottom: 30px;
  }

  .cut-31 {
    font-size: 30px;
    list-style: 30px;
  }

  .rangss {
    margin-left: 1px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .cut-1 {
    margin-top: 10px;
  }

  /* Rigorous end */

  /* Remind start */

  .band {
    margin-left: 5px;
  }

  .Magg-11 {
    margin-top: 1px;
  }

  .responsiveClass
  /* Remond end */

  /* Unqiue */

  .manage-1 {
    width: 100%;
    height: 100%;
  }

  .manage-3 {
    font-weight: 400;
    font-size: 27px;
    font-family: "Wensley";
    color: #ffffff;
  }

  /* Unique end */

  /* Asset start */

  .asset {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .Asset-13 {
    font-weight: 400;
    font-size: 30px;
    font-family: "Wensley";
    color: #282828;
  }

  /* Asset end */

  /* Allocation comp */

  .Allocate {
    font-weight: 400;
    font-size: 30px;
    font-family: "Wensley";
    color: #ffffff;
  }

  .Allocate-4 {
    font-weight: 400;
    font-size: 30px;
    font-family: "Wensley";
    color: #282828;
  }

  .speed-4 {
    margin-top: 10px;
  }

  .Magg {
    background-color: #282828;
    height: 100%;
  }

  /* Allocation end */

  /* Team :start */

  /* Board */

  .Direct {
    font-size: 25px;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  .tab-content {
    margin-top: 1px;
  }

  img.popp {
    width: 100%;
    margin-bottom: 20px;
  }

  .Bena {
    font-size: 25px;
  }

  .coock {
    padding-top: 20px;

    font-size: 25px;
  }

  ul.loof {
    color: #ffffff;
  }

  li.long {
    font-weight: 400;
    font-size: 11px;
    font-family: "Montserrat", sans-serif;
    color: FFFFFF !important;
    line-height: 25px;
  }

  /* Board end  */
  /* team and */

  /* Contact start */
  .oop {
    border-bottom: 1px solid #ffffff33;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .contact {
    font-weight: 400;
    font-size: 25px;
    font-family: "Wensley";
    color: #ffffff;
  }

  .section-2 {
    background-color: #282828;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .limi {
    padding-top: 12px;
  }

  .swiss {
    font-weight: 400;
    font-size: 30px;
    font-family: "Wensley";
    color: #ffffff;
  }

  .Geneva {
    line-height: 21px;
  }

  .soft {
    padding-left: 20px;
  }

  .soft-1 {
    padding-left: 20px;
  }

  ul.feet {
    padding: 0;
  }

  /* Contact end */

  /* Ring css */
  .logo img {
    z-index: 1;
    width: 26%;
  }

  .ring-main {
    zoom: 36%;
    z-index: 1;
  }

  .omeg {
    height: 75vh;
    margin-top: -500px;
  }

  .port-1 {
    height: 75vh;
    margin-top: -500px;
  }

  .Avers {
    height: 75vh;
    margin-top: -500px;
  }

  .Coverage {
    height: 75vh;
    margin-top: -500px;
  }

  .scan {
    height: 75vh;
    margin-top: -500px;
  }

  .soff {
    height: 75vh;
    margin-top: -500px;
  }

  .Doc {
    height: 75vh;
    margin-top: -500px;
  }

  .Duret {
    height: 75vh;
    margin-top: -500px;
  }

  .navigation-circle {
    margin-top: 200px !important;
  }

  /* Ring end */

  /* footer start */

  .feet li {
    display: block;
    padding-left: 1px;
    padding-top: 20px;
  }

  .floop {
    /* padding-left: 5px;
  padding-right: 5px; */
    padding-left: 10px;
  }

  .type {
    width: 300px;
    height: 39px;
  }

  ul.feet {
    text-align: start;
  }

  .fame {
    font-weight: 300;
    font-size: 25px;
    font-family: "Wensley";
    color: #ffffff4d;
  }

  .roofi {
    text-align: start;
    padding-top: 15px;
  }

  .foom {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  /* footer end */

  /* news tab */
  #strip-w {
    width: 100%;
    min-width: 100% !important;
  }

  .popp {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .noen {
    font-size: 50px;
  }

  /* tab end */

  /* news0-inside */
  .Ethic {
    width: 80%;
  }

  nav ul a {
    color: #ffffff !important;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    font-family: "Wensley";
  }

  .log {
    width: 40%;
  }

  .cond {
    border-top: none;
    margin-top: 20px;
  }

  .Magg-1111 {
    height: 150vh;
    background-color: #282828;
  }

  .Along {
    font-size: 25px;
  }

  .pock iframe {
    width: 100%;
    height: 50%;
  }

  .swiper-slide {
    text-align: center;
  }

  .purif {
    text-align: center;
  }

  .portable {
    text-align: center;
    margin-bottom: 15px;
  }

  .portable-1 {
    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    color: #282828;
    line-height: 25px;
    text-align: center;
    margin-bottom: 15px;
  }

  .clip {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}

/* Ring css */
.ring-main {
  display: flex;
  height: 95vh;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navigation-circle {
  display: block;
  position: relative;
  height: 435px;
  width: 435px;
  margin: auto;
  margin-top: 75px;
}

.navigation-circle__inner {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.navigation-circle__list {
  display: block;
  position: absolute;
  height: 450px;
  width: 450px;
  top: 50%;
  /* Center the circle vertically */
  left: 50%;
  /* Center the circle horizontally */
  transform: translate(-50%, -50%) rotate(-90deg);
  /* Center the circle and rotate */
  animation: 2.2s cubic-bezier(0.25, -0.25, 0.35, 1) 0 1 animate-in-list forwards;
}

.navigation-circle-svg {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: rotateZ(-90deg);
}

.navigation-circle-svg--opaque {
  opacity: 0.5;
}

.navigation-circle-svg--mask circle {
  transition: all 0.5s ease;
  transition-delay: 0.5s;
  animation: 2.2s ease 0 1 animate-in-svg-circle-mask backwards;
}

.navigation-circle-list-item {
  display: block;
  position: absolute;
  height: 0;
  width: calc(50% + 16px);
  top: 50%;
  left: 50%;
  list-style: none;
  transform-origin: 0 0;
  background-color: transparent;
  /* Remove the white background */
  transition: all 0.3s ease;
  /* Add the transition for smoother animation */
}

.navigation-circle-list-item__point {
  display: block;
  position: absolute;
  height: 32px;
  width: 32px;
  top: -16px;
  right: 2px;
  cursor: pointer;
  transform: scale(0);
  /* display: flex;
  align-items: center; */
}

.navigation-circle-list-item__point:before {
  content: "•";
  display: block;
  position: absolute;
  height: 15px;
  width: 15px;
  top: 0;
  color: #ffffff;
  background-color: #ffffff;
  font-size: 0px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 0px #ffffff;
  transform: scale(0.75);
  transition: all 0.5s ease;
}

.navigation-circle-list-item__point:after {
  /* content: ""; */
  display: block;
  position: absolute;
  height: 1px;
  width: 0px;
  top: 18px;
  left: 31px;
  background-color: #ffffff;
  transition: all 0.5s ease;
}

.navigation-circle-list-item__meta {
  display: block;
  position: absolute;
  overflow: hidden;
  opacity: 1;
  transform-origin: center;
  margin-left: 20px;
  min-width: 68px;
  padding: 4px;
}

.navigation-circle-list-item__title {
  display: block;
  color: #f7f7f7;
  text-align: left;
  font-size: 13px;
  padding-bottom: 4px;
  margin-bottom: 6px;
  font-family: "Wensley";
}

.navigation-circle-list-item__subtitle {
  display: block;
  color: #f7f7f7;
  text-align: center;
  font-weight: 200;
  font-size: 8px;
}

.navigation-circle-list-item:nth-of-type(1) {
  transform: rotateZ(calc((360deg / 7) * 1));
}

.navigation-circle-list-item:nth-of-type(1) .navigation-circle-list-item__meta {
  transform: rotateZ(38.5714285714deg);
}

.navigation-circle-list-item:nth-of-type(1) .navigation-circle-list-item__point {
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0s 1 animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(2) {
  transform: rotateZ(calc((360deg / 7) * 2));
}

.navigation-circle-list-item:nth-of-type(2) .navigation-circle-list-item__meta {
  transform: rotateZ(-12.8571428571deg);
}

.navigation-circle-list-item:nth-of-type(2) .navigation-circle-list-item__point {
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0.2s 1 animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(3) {
  transform: rotateZ(calc((360deg / 7) * 3));
}

.navigation-circle-list-item:nth-of-type(3) .navigation-circle-list-item__meta {
  transform: rotateZ(-64.2857142857deg);
}

.navigation-circle-list-item:nth-of-type(3) .navigation-circle-list-item__point {
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0.4s 1 animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(4) {
  transform: rotateZ(calc((360deg / 7) * 4));
}

.navigation-circle-list-item:nth-of-type(4) .navigation-circle-list-item__meta {
  transform: rotateZ(-115.7142857143deg);
}

.navigation-circle-list-item:nth-of-type(4) .navigation-circle-list-item__point {
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0.6s 1 animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(5) {
  transform: rotateZ(calc((360deg / 7) * 5));
}

.navigation-circle-list-item:nth-of-type(5) .navigation-circle-list-item__meta {
  transform: rotateZ(-167.1428571429deg);
}

.navigation-circle-list-item:nth-of-type(5) .navigation-circle-list-item__point {
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 0.8s 1 animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(6) {
  transform: rotateZ(calc((360deg / 7) * 6));
}

.navigation-circle-list-item:nth-of-type(6) .navigation-circle-list-item__meta {
  transform: rotateZ(-218.5714285714deg);
}

.navigation-circle-list-item:nth-of-type(6) .navigation-circle-list-item__point {
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 1s 1 animate-in-list-item-point forwards;
}

.navigation-circle-list-item:nth-of-type(7) {
  transform: rotateZ(calc((360deg / 7) * 7));
}

.navigation-circle-list-item:nth-of-type(7) .navigation-circle-list-item__meta {
  transform: rotateZ(-270deg);
}

.navigation-circle-list-item:nth-of-type(7) .navigation-circle-list-item__point {
  animation: 1s cubic-bezier(0.55, -0.3, 0.6, 1.5) 1.2s 1 animate-in-list-item-point forwards;
}

/* .navigation-circle-list-item:hover .navigation-circle-list-item__point:before {
  transform: scale(1);                                                                      comment by me 
  font-size: 0px;
} */
.navigation-circle-list-item:hover .navigation-circle-list-item__point:after {
  width: 32px;
  left: 34px;
}

.navigation-circle-list-item:hover .navigation-circle-list-item__meta {
  opacity: 1;
}

.navigation-circle-list-item:active .navigation-circle-list-item__point:before,
.navigation-circle-list-item.active .navigation-circle-list-item__point:before {
  transform: scale(0.85);
  color: #ffffff;
  box-shadow: inset 0px 0px 0px 16px #ffffff;
  border-color: transparent;
}

.navigation-circle-list-item:active .navigation-circle-list-item__point:after,
.navigation-circle-list-item.active .navigation-circle-list-item__point:after {
  width: 34px;
  left: 32px;
}

.navigation-circle-list-item:active .navigation-circle-list-item__meta,
.navigation-circle-list-item.active .navigation-circle-list-item__meta {
  opacity: 1;
}

@keyframes animate-in-list {
  0% {
    transform: rotate(-540deg);
  }

  100% {
    transform: rotate(-90deg);
  }
}

@keyframes animate-in-svg-circle-mask {
  0% {
    stroke-dashoffset: 1005;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-in-list-item-point {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

/* menu */

@import url(https://use.fontawesome.com/releases/v5.0.6/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Oswald:400|Raleway:400,700,400italic,700italic);

.screen-reader-text {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

/************************************************/
/****************** NAVIGATION ******************/
/************************************************/
nav {
  background: #e5e5e500;

  color: #1d1f20;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 9999;
}

/*** INPUT ***/
nav input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

/*** LABEL ***/
nav label {
  cursor: pointer;
  display: block;
  font: 2em/1 "Oswald", sans-serif;
  padding: 0.5em;
}

/* Hamburger Icon */
#menu-icon,
#menu-icon:before,
#menu-icon:after {
  background: #ffffff;
  border-radius: 0.05em;
  height: 0.1em;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

#menu-icon {
  display: inline-block;
  margin: 0.4em 0;
  max-width: 1em;
  position: relative;
}

nav label #menu-icon {
  float: right;
}

#menu-icon:before,
#menu-icon:after {
  content: "";
  left: 0;
  position: absolute;
}

#menu-icon:before {
  top: -0.4em;
}

#menu-icon:after {
  bottom: -0.4em;
}

/* Close Icon */
nav input[type="checkbox"]:checked+label #menu-icon {
  background: transparent;
}

nav input[type="checkbox"]:checked+label #menu-icon:before {
  top: 0;
  transform: rotate(-45deg);
}

nav input[type="checkbox"]:checked+label #menu-icon:after {
  bottom: 0;
  transform: rotate(45deg);
}

#wrapper {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

#top-oval {
  position: absolute;
  top: 0;
  height: 50px;
  width: 100%;
  background: white;
  border-bottom-left-radius: 70%;
  border-bottom-right-radius: 70%;
}

#bottom-oval {
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background: white;
}

#strip-w {
  width: 100%;
  min-width: 1200px;
  height: 300px;
  display: flex;
  flex-wrap: no-wrap;
  align-items: flex-start;
  overflow-x: auto;
}

#strip-item {
  height: 280px;
  width: 250px;
  min-width: 300px;
  margin-right: 10px;
  background: green;
  background-size: cover;
  background-image: url(./images/slide-1.png);
}

#strip-item-2 {
  height: 280px;
  width: 250px;
  min-width: 300px;
  margin-right: 10px;
  background: green;
  background-size: cover;
  background-image: url(./images/slide-2.png);
}

#strip-item-3 {
  height: 280px;
  width: 250px;
  min-width: 300px;
  margin-right: 10px;
  background: green;
  background-size: cover;
  background-image: url(./images/sldie-3.png);
}

#strip-item-4 {
  height: 280px;
  width: 250px;
  min-width: 300px;
  margin-right: 10px;
  background: green;
  background-size: cover;
  background-image: url(./images/slide-1.png);
}

#strip-item-5 {
  height: 280px;
  width: 250px;
  min-width: 300px;
  margin-right: 10px;
  background: green;
  background-size: cover;
  background-image: url(./images/slide-2.png);
}

#strip-item-6 {
  height: 280px;
  width: 250px;
  min-width: 300px;
  margin-right: 10px;
  background: green;
  background-size: cover;
  background-image: url(./images/sldie-3.png);
}

/* header menu */

@import url(https://use.fontawesome.com/releases/v5.0.6/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Oswald:400|Raleway:400,700,400italic,700italic);

*,
*:before,
*:after {
  box-sizing: border-box;
}

.screen-reader-text {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

/************************************************/
/****************** NAVIGATION ******************/
/************************************************/
nav {
  background: transparent;
  color: #ffffff;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 9999;
}

/*** INPUT ***/
nav input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

/*** LABEL ***/
nav label {
  cursor: pointer;
  display: block;
  font: 2em/1 "Oswald", sans-serif;
  padding: 0.5em;
}

/* Hamburger Icon */
#menu-icon,
#menu-icon:before,
#menu-icon:after {
  background: #ffffff;
  border-radius: 0.05em;
  height: 0.1em;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

#menu-icon {
  display: inline-block;
  margin: 0.4em 0;
  max-width: 1em;
  position: relative;
}

nav label #menu-icon {
  float: right;
}

#menu-icon:before,
#menu-icon:after {
  content: "";
  left: 0;
  position: absolute;
}

#menu-icon:before {
  top: -0.4em;
}

#menu-icon:after {
  bottom: -0.4em;
}

/* Close Icon */
nav input[type="checkbox"]:checked+label #menu-icon {
  background: transparent;
}

nav input[type="checkbox"]:checked+label #menu-icon:before {
  top: 0;
  transform: rotate(-45deg);
}

nav input[type="checkbox"]:checked+label #menu-icon:after {
  bottom: 0;
  transform: rotate(45deg);
}

/*** MENU ***/
/* Overlay */
nav input:checked~#overlay {
  background: #282828;
  bottom: 0;
  left: 0;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: -1;
}

/* List */
nav ul {
  font-size: 1.5em;
  list-style: none;
  margin: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
}

#menu-icon,
#menu-icon:before,
#menu-icon:after {
  background: #ffffff;
  border-radius: 0.05em;
  height: 0.05em;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

#menu-icon:before {
  top: -0.4em;
  /* display: none; */
}

nav input:checked~ul {
  margin: 1em;
  max-height: inherit;
  opacity: 1;
}

nav ul>li {
  margin: 0.5em 0;
}

nav ul a {
  color: #ffffff !important;
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  font-family: "Wensley";
  margin: auto;
}

nav ul a:hover {
  text-decoration: underline;
}

/* Social Media */
nav ul#social-media>li {
  display: inline-block;
  font-size: 1.5em;
  margin: 0.5em;
}

/* Curved caurosle start */

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
  text-align: center;
}

/* Curved caurosle end */

/* Add these styles to your CSS file */
.text-justify {
  text-align: justify;
}

.video-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the last value for transparency */
}